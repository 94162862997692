import React, { useContext, useState } from 'react';
import axios from "axios";
import AuthContext from '../context/AuthContext';
import { Pane, Paragraph, Heading, TextInputField, Tablist, Tab, Button, toaster } from 'evergreen-ui';
import env from "react-dotenv";

const LoginPage = () => {

    let {loginUser} = useContext(AuthContext);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [tabs] = React.useState(['Login', 'Sign Up']);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [formData, setFormData] = useState({
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    });

    const handleChange = (name, value) => {
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      axios.post(`${env.BASE_URL}/register/`, formData)
        .then(response => {
          // Handle successful registration
          console.log(response);
          loginUser(formData.username, formData.password);
        })
        .catch(error => {
          // Handle registration errors
          console.log(error);
        });
    };

    const handleLogin = () => {
      if (username && password) {
        loginUser(username, password);
      } else {
        toaster.notify('Please enter a username and password')
      }
    }

    const isValid = () => {
      let valid = formData.password.length >= 8 
        && formData.password.match(/[A-Z]/) != null
        && formData.password.match(/[a-z]/) != null
        && formData.password.match(/[0-9]/) != null
        && formData.password.match(/[!@#$%^&*]/) != null;
      return valid;
    }

    const canSignUp = () => {
      if (isValid() && formData.username && formData.email) {
        return true;
      } else {
        return false;
      }
    }

    return (
      <Pane 
        className="fullScreenWrapper"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Pane>
          <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
            {tabs.map((tab, index) => (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          <Pane padding={16} background="tint1" flex="1">
            {tabs.map((tab, index) => (
              <Pane
                aria-labelledby={tab}
                aria-hidden={index !== selectedIndex}
                display={index === selectedIndex ? 'block' : 'none'}
                key={tab}
                role="tabpanel"
              >
                {tab === "Login" && (
                  <Pane width={320}>
                    <Heading marginBottom={12}>Sign Log In:</Heading>
                    <TextInputField
                      label="Username"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                    <Pane display="flex" flexDirection="column" marginBottom={12}>
                      <TextInputField
                        label="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        marginBottom={0}
                      />
                    </Pane>
                    <Button onClick={handleLogin}>Sign In</Button>
                  </Pane>
                )}
                {tab === "Sign Up" && (
                  <Pane>
                    <Heading marginBottom={12}>Sign Up for an Account:</Heading>
                    <Pane display="flex" flexDirection="row">
                      <TextInputField
                        isInvalid={false}
                        label="First Name"
                        value={formData.first_name}
                        onChange={e => handleChange("first_name", e.target.value)}
                        marginRight={12}
                      />
                      <TextInputField
                        isInvalid={false}
                        label="Last Name"
                        value={formData.last_name}
                        onChange={e => handleChange("last_name", e.target.value)}
                      />
                    </Pane>
                    <TextInputField
                      isInvalid={false}
                      required
                      label="Username"
                      validationMessage={formData.username ? undefined : "This field is required"}
                      value={formData.username}
                      onChange={e => handleChange("username", e.target.value)}
                    />
                    <TextInputField
                      isInvalid={!formData.email.match(/\S+@[^.]+\.[a-zA-Z]+/)}
                      required
                      label="Email Address"
                      validationMessage={(formData.email && formData.email.match(/\S+@[^.]+\.[a-zA-Z]+/)) ? undefined : "This field is required and must be a valid email address"}
                      value={formData.email}
                      onChange={e => handleChange("email", e.target.value)}
                    />
                    <Pane display="flex" flexDirection="column" marginBottom={12}>
                      <TextInputField
                        isInvalid={!isValid()}
                        required
                        label="Password"
                        validationMessage={isValid() ? undefined : "This field is required and must meet the following requirements:"}
                        value={formData.password}
                        onChange={e => handleChange("password", e.target.value)}
                        marginBottom={0}
                      />
                      <Pane>
                        <Paragraph size={300}>* Minimum 8 characters</Paragraph>
                        <Paragraph size={300}>* Include at least one uppercase letter, one lowercase letter, and one number</Paragraph>
                        <Paragraph size={300}>* Include one of: !@#$%^&*</Paragraph>
                      </Pane>
                    </Pane>
                    <Button disabled={!canSignUp()} onClick={handleSubmit}>Sign Up</Button>
                  </Pane>
                )}
              </Pane>
            ))}
          </Pane>
        </Pane>
      </Pane>
    )
}

export default LoginPage;