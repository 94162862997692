import React, { useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Pane, Paragraph, TextInput, Checkbox, Button } from 'evergreen-ui';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ReportOptions = (props) => {
  const [ltr, setLtr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [range, setRange] = useState(false);
  const [firstDate, setFirstDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  const assembleQuery = () => {
    let q = [];
    if (range) {
      q.push(`rs=${firstDate}&re=${lastDate}`);
    }
    if (ltr) {
      q.push(`ltr=${ltr}`);
    }
    if (success) {
      q.push(`success=${success}`);
    }
    if (fail) {
      q.push(`fail=${fail}`);
    }
    return "&"+q.join("&");
  }

  return (
    <Pane display="flex" flexDirection="column" marginY={16} marginX={16}>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Reads LTR"
          checked={ltr}
          onChange={e => setLtr(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Conversion Status: Success"
          checked={success}
          onChange={e => setSuccess(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Conversion Status: Fail"
          checked={fail}
          onChange={e => setFail(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Conversion date range:"
          checked={range}
          onChange={e => setRange(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
        <DatePicker 
          disabled={!range} 
          selected={firstDate} 
          onChange={(date) => {
            setFirstDate(date.getTime());
          }} 
        />
        <Paragraph size={300} marginX={12}>through</Paragraph>
        <DatePicker 
          disabled={!range} 
          selected={lastDate} 
          onChange={(date) => {
            setLastDate(date.getTime());
          }} 
        />
      </Pane>
      <Paragraph marginTop={8}>
        <Button 
          onClick={e => {
            let tmpQuery = assembleQuery();
            props.handleSearch(tmpQuery, "reportstab");
            props.handleLoadBooks(true);
          }} 
        >
          Search
        </Button>
      </Paragraph>
    </Pane>
  );
}

export default ReportOptions;
