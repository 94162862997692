import React, { useEffect, useState } from "react";
import { Pane, Paragraph } from 'evergreen-ui';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import env from "react-dotenv";

const ReportDownload = (props) => {

  const parseReportData = (delimeter, lineEnd, quoteRe, asString) => {
    let headerRow = [];
    let body = [];
    for (let i=0; i < props.books.length; i++) {
      let book = props.books[i];
      let bodyRow = [];
      for (let k in book) {
        if (i === 0) {
          headerRow.push(k);
        }
        if (book[k]) {
          let content = book[k];
          if (k === "conversions") {
            let str = "";
            for (let j=0; j<content.length; j++) {
              str += `Date: ${content[j].created}${lineEnd}`;
              str += `Status: ${content[j].status}${lineEnd}`;
              str += `By: ${content[j].user}${lineEnd}${lineEnd}`;
            }
            content = str;
          } else if (k === "histories") {
            let str = "";
            for (let j=0; j<content.length; j++) {
              str += `Date: ${content[j].created}${lineEnd}`;
              str += `By: ${content[j].user}${lineEnd}`;
              str += `Detail: ${content[j].detail}${lineEnd}${lineEnd}`;
            }
            content = str;
          } else if (typeof book[k] === "string") {
            content = content.replaceAll('"', quoteRe);
          }
          bodyRow.push(delimeter+content+delimeter);
        } else {
          bodyRow.push(" ");
        }
      }
      if (asString) {
        body.push(bodyRow.join(","));
      } else {
        body.push(bodyRow);
      }
    }
    return [headerRow, body];
  }

  const makeCSV = () => {
    let [headerRow, body] = parseReportData('"', '; ', '""', true);
    let csvContent = headerRow.join(",") + "\n" + body.join("\n");
    return csvContent;
  }

  const downloadCSV = () => {
    let csvContent = makeCSV();
    let now = new Date().getTime();
    const blob = new Blob([csvContent], { type: "text/csv" });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = `REPORT_${now}.csv`;
    document.body.appendChild(element);
    element.click();
  }

  const makePDF = () => {
    const doc = new jsPDF({
      orientation: "landscape"
    });

    let [headerRow, body] = parseReportData('', '\n', '', false);

    console.log(body);

    autoTable(doc, {
      head: [headerRow],
      body: body,
    });

    let now = new Date().getTime();
    doc.save(`REPORT_${now}.pdf`);
  }

  return (
    <Pane display="flex" flexDirection="row" alignItems="center">
      <Paragraph marginRight={8}>Download report:</Paragraph>
      <Paragraph marginRight={8} color="blue500" onClick={e => downloadCSV()}>CSV</Paragraph>
      <Paragraph marginRight={8}>|</Paragraph>
      <Paragraph color="blue500" onClick={e => makePDF()}>PDF</Paragraph>
    </Pane>
  )
}

export default ReportDownload;