import { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import env from "react-dotenv";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {

    let [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null));
	let [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null));
    let [email, setEmail] = useState(null);
	let [shouldCheck, setShouldCheck] = useState(true);
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    // TO DO: Adjust to use fixed vals rather than e
    let loginUser = async (u, p) => {
        // e.preventDefault();
        const response = await fetch(`${env.BASE_URL}/authapi/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: u, password: p })
        });

        let data = await response.json();
        // With wrong credentials the code get stuck with parsing error message
        // because the response has text message not the access and request tocken in required format
        // performing a check on success of login will prevent this error.
        // a robust error handling can be implemented but below modification a work around to carry on with the tutorial

        if (data && response.ok) {
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            setEmail(u);
            navigate('/app');
        } else {
            alert('Check login credentials :Something went wrong while logging in the user!');
        }
    }

    let logoutUser = () => {
        // e.preventDefault()
        localStorage.removeItem('authTokens');
        setAuthTokens(null);
        setUser(null);
        navigate('/');
    }

    const updateToken = async () => {
    	console.log(authTokens);
        const response = await fetch(`${env.BASE_URL}/authapi/token/refresh/`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body:JSON.stringify({refresh:authTokens?.refresh})
        })
       
        const data = await response.json();
        if (response.status === 200) {
            console.log(data);
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem('authTokens',JSON.stringify(data));
        } else {
            logoutUser();
        }

        if (loading) {
            setLoading(false);
        }
    }

    // let contextData = {
    //     user:user,
    //     email:email,
    //     authTokens:authTokens,
    //     loginUser:loginUser,
    //     logoutUser:logoutUser,
    // }

    useEffect(() => {
    	if (shouldCheck) {
		    const REFRESH_DELAY = 1000 * 60 * 4; // 4 minutes

		    let timeout = setTimeout(() => {
		        if (authTokens) {
		            updateToken(); // only refresh once per day
		            setShouldCheck(false);
		        }
		    }, REFRESH_DELAY);

		    return () => clearTimeout(timeout); // Clean up the timeout if the component unmounts
		}
	}, [shouldCheck]);

    return(
        <AuthContext.Provider value={{user:user, email:email, authTokens:authTokens, loginUser:loginUser, logoutUser:logoutUser}}>
            {children}
        </AuthContext.Provider>
    )
}