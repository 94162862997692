import React from "react";
import { Pane, Paragraph, Strong, Text, ChevronRightIcon } from 'evergreen-ui';

const MainTabs = (props) => {
  const { requestWithAuth } = props;

  const tabs = [
    {
      label: "Upload",
      name: "uploadtab",
      route: "app",
      color: "yellow"
    },
    {
      label: "Books & Downloads",
      name: "bookstab",
      route: "app",
      color: "orange"
    },
    {
      label: "Reports",
      name: "reportstab",
      route: "app",
      color: "purple"
    },
    {
      label: "Search",
      name: "searchtab",
      route: "app",
      color: "pink"
    }
  ];

  const onSelectTab = props.onSelectTab;

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start" 
      justifyContent="flex-start" 
      width="100%"
    >
      {tabs.map((tab) => <MainTab key={tab.name} selectedTab={props.selectedTab} label={tab.label} name={tab.name} route={tab.route} onSelectTab={onSelectTab} />)}
    </Pane>
  );
}

const MainTab = (props) => {

  return (
    <Pane 
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={12}
      height="50px" 
      width="300px" 
      background={props.selectedTab === props.name ? "gray300" : "tint1"}
      border="default"
      onClick={e => props.onSelectTab(props.name)}
    >
      <Paragraph>
        {props.selectedTab === props.name ? (
          <Strong>{props.label}</Strong>
        ) : (
          <Text>{props.label}</Text>
        )}
      </Paragraph>
      {props.selectedTab === props.name && (
        <ChevronRightIcon />
      )}
    </Pane>
  )
}

export default MainTabs;
