import React, { useState, useEffect } from "react";
import { Pane, Paragraph, Strong, Tab, Tablist, Button, Text, Checkbox, PlusIcon } from 'evergreen-ui';
import DropFile from "./DropFile";
import ConversionSettings from "./ConversionSettings";
import Note from "./Note";
import ReplacePage from "./ReplacePage";
import InsertPage from "./InsertPage";
import CustomPages from "./CustomPages";
import ReplacementsList from "./ReplacementsList";
import Download from "./Download";
import env from "react-dotenv";

const BookTabs = (props) => {
  const { requestWithAuth } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs] = useState(['New Conversion', 'Notes and History']);

  return (
    <Pane paddingY={12}>
      <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
        {tabs.map((tab, index) => (
          <Tab
            aria-controls={`panel-${tab}`}
            isSelected={index === selectedIndex}
            key={tab}
            onSelect={() => setSelectedIndex(index)}
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      <Pane padding={16} flex="1" borderTop="default">
        {tabs.map((tab, index) => (
          <Pane
            aria-labelledby={tab}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? 'block' : 'none'}
            key={tab}
            role="tabpanel"
          >
            {tab === "New Conversion" && (
              <ConvertBook metadata={props.metadata} handleTriggerConversion={props.handleTriggerConversion} />
            )}
            {tab === "Edit Files" && (
              <EditBook metadata={props.metadata} />
            )}
            {tab === "Notes and History" && (
              <BookHistory metadata={props.metadata} handleAddHistory={props.handleAddHistory} />
            )}
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

const ConvertBook = (props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [interior, setInterior] = useState([]);
  const [cover, setCover] = useState([]);
  const [copyright, setCopyright] = useState([]);
  const [note, setNote] = useState("");
  const [replacements, setReplacements] = useState([]);
  const [showReplacements, setShowReplacements] = useState(false);
  const [conversionSettings, setConversionSettings] = useState({
    pdfOnly: false, 
    epubOnly: false, 
    coverOnly: false, 
    noCover: false
  });

  const updateConversionSettings = (updated) => {
    setConversionSettings({...conversionSettings, ...updated});
    console.log(conversionSettings);
  }

  const updateReplacements = (updated) => {
    console.log(updated);
    setReplacements([...replacements, updated]);
    console.log(replacements);
  }

  const removeReplacement = (number) => {
    let updated = replacements.filter(r => r.page !== number);
    setReplacements(updated);
    console.log(replacements);
  }

  const runConversion = async () => {
    // create item object
    let item = {...props.metadata};
    if (copyright.length > 0) {
      item["copyright"] = copyright[0].path;
      item["copyright_file"] = copyright[0];
    }
    if (cover.length > 0) {
      item["cover"] = cover[0].path;
      item["cover_file"] = cover[0];
    }
    if (interior.length > 0) {
      item["interior"] = interior[0].path;
      item["interior_file"] = interior[0];
    }
    if (replacements.length > 0) {
      item["replacements"] = replacements;
    }
    // trigger conversion
    if (item.hasOwnProperty("interior") || conversionSettings.coverOnly === true) {
      props.handleTriggerConversion(item, note, conversionSettings);
    }
    // reset fields
    setConversionSettings({
      pdfOnly: false, 
      epubOnly: false, 
      coverOnly: false, 
      noCover: false
    });
    setInterior([]);
    setCover([]);
    setCopyright([]);
    setReplacements([]);
    setNote("");
  }

  let howmany = replacements.length > 0 ? "another" : "a";

  return (
    <Pane
      display="flex"
      flex-direction="row"
      justifyContent="space-around"
      alignItems="flex-start"
      padding={12}
    >
      <Pane marginRight={16}>
        <DropFile label="Interior PDF..." type="pdf" handleFileData={setInterior} pdfFiles={interior} compact={true} />
        <DropFile label="Cover..." type="pdf" handleFileData={setCover} pdfFiles={cover} compact={true} />
        <DropFile label="Copyright..." type="pdf" handleFileData={setCopyright} pdfFiles={copyright} compact={true} />
        {replacements.map(r => {
          if (r.mode === "replace") {
            return <ReplacePage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          } else {
            return <InsertPage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
          }
        })}
        <Button onClick={e => setShowReplacements(true)}><PlusIcon marginRight={12} /> Add {howmany} custom page...</Button>
        <CustomPages handleSetShown={setShowReplacements} show={showReplacements} handleAddCustomPage={updateReplacements} />
      </Pane>
      <Pane>
        {error && (<Paragraph maxWidth="340px" size={300} color="red">{error}</Paragraph>)}
        <ConversionSettings updateConversionSettings={updateConversionSettings} />
        <Note value={note} handleChange={setNote} handleRemoveNote={e => setNote("")} />
        <Button disabled={interior.length === 0} onClick={e => runConversion()} marginTop={12} appearance="primary" intent="success" size="large">Go</Button>
      </Pane>
    </Pane>
  )
}

const EditBook = (props) => {
  const [note, setNote] = useState("");
  const [replacements, setReplacements] = useState([]);
  const [showReplacements, setShowReplacements] = useState(false);
  const [updatePdf, setUpdatePdf] = useState(false);
  const [updateEpub, setUpdateEpub] = useState(false);
  const [updateMetadata, setUpdateMetadata] = useState(false);

  const updateReplacements = (updated) => {
    console.log(updated);
    setReplacements([...replacements, updated]);
  }

  const removeReplacement = (number) => {
    let updated = replacements.filter(r => r.page !== number);
    setReplacements(updated);
  }

  let howmany = replacements.length > 0 ? "another" : "a";

  if (
    props.metadata.conversions && 
    props.metadata.conversions.length > 0 && 
    (props.metadata.conversions[0].pdf_download || props.metadata.conversions[0].epub_download)
  ) {
    return (
      <Pane
        display="flex"
        flex-direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={12}
      >
        <Pane marginRight={32}>
          {replacements.map(r => {
            if (r.mode === "replace") {
              return <ReplacePage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
            } else {
              return <InsertPage data={r} handleRemoveReplacement={removeReplacement} key={r.page} />
            }
          })}
          <Button onClick={e => setShowReplacements(true)}><PlusIcon marginRight={12} /> Add {howmany} custom page...</Button>
          <CustomPages handleSetShown={setShowReplacements} show={showReplacements} handleAddCustomPage={updateReplacements} />
          <Pane marginTop={12}>
            <Note value={note} handleChange={setNote} handleRemoveNote={e => setNote("")} />
          </Pane>
        </Pane>
        <Pane>
          {props.metadata.conversions[0].pdf_download && (
            <Checkbox checked={updatePdf} onChange={e => setUpdatePdf(!updatePdf)} label={`Update PDF: ${props.metadata.conversions[0].pdf_download}`} />
          )}
          {props.metadata.conversions[0].epub_download && (
            <Pane>
              <Checkbox checked={updateEpub} onChange={e => setUpdateEpub(!updateEpub)} label={`Update EPUB: ${props.metadata.conversions[0].epub_download}`} />
              <Checkbox checked={updateMetadata} onChange={e => setUpdateMetadata(!updateMetadata)} label={`Update EPUB Metadata`} />
            </Pane>
          )}
          <Button onClick={e => console.log("hi")} marginTop={12} appearance="primary" intent="success" size="large">Go</Button>
        </Pane>
      </Pane>
    )
  } else {
    return (
      <Pane>
        <Paragraph>No files available to edit. To create files, convert this book first.</Paragraph>
      </Pane>
    )
  }
}

const BookHistory = (props) => {
  const { requestWithAuth } = props;
  const [allhistory, setAllhistory] = useState([]);
  const [note, setNote] = useState("");
  const [mode, setMode] = useState("local");
  const [downloads, setDownloads] = useState([]);
  const [showdownloads, setShowdownloads] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    let conversions = [];
    if (props.metadata.hasOwnProperty("conversions")) {
      conversions = props.metadata.conversions;
    }
    let histories = [];
    if (props.metadata.hasOwnProperty("histories")) {
      histories = props.metadata.histories;
    }
    let combined = [...conversions, ...histories];
    combined.sort((a, b) => new Date(b.created) - new Date(a.created));
    setAllhistory(combined);
  }, [props.metadata]);

  const handleAddNote = () => {
    props.handleAddHistory("note", note);
    // add the new note to the history, 
    // to avoid reloading.
    // 2024-12-18T21:24:24
    let date = new Date();
    let formatted = date.getFullYear()
      +"-"
      +(date.getMonth()+1)
      +"-"
      +date.getDate()
      +"T"
      +date.getHours()
      +":"
      +date.getMinutes()
      +":"
      +date.getSeconds();
    let tmp = [{created: formatted, user: "You", detail: note, action: "note"}];
    setAllhistory([...tmp, ...allhistory]);
    // reset note
    setNote("");
  }

  return (
    <Pane>
      <Pane maxHeight={320} overflow="scroll">
        {allhistory.map(h => {
          if (h.hasOwnProperty("action")) {
            return <Paragraph key={h.created} marginTop={12} size={300}><Strong size={300}>{h.created.replace(/\.\S+Z$/, "")}:</Strong> {h.user}: {h.detail}</Paragraph>;
          } else {
            let opts = [];
            for (let k in h.options) {
              if (h.options[k] === true) {
                opts.push(k);
              }
            }
            return (
              <Pane key={h.created} marginTop={12}>
                <Paragraph size={300}><Strong size={300}>{h.created.replace(/\.\S+Z$/, "")}:</Strong> {h.user}: New conversion ({h.status}):</Paragraph>
                <Paragraph size={300}>Conversion details:</Paragraph>
                <Paragraph size={300}>Interior PDF: {h.interior}</Paragraph>
                <Paragraph size={300}>Cover File: {h.cover ? h.cover : "NONE"}</Paragraph>
                <Paragraph size={300}>Copyright File: {h.copyright ? h.copyright : "NONE"}</Paragraph>
                <Pane size={300}><Text size={300}>Replacements:</Text> {h.replacements ? <ReplacementsList replacements={h.replacements} /> : <Text size={300}>NONE</Text>}</Pane>
                <Paragraph size={300}>Export Options: {opts.length > 0 ? opts.join(", ") : "NONE"}</Paragraph>
                <Pane display="flex" flexDirection="row">
                  <Paragraph size={300} marginRight={12}>Downloads:</Paragraph>
                  {!h.epub_download && !h.pdf_download && !h.cover_download && !h.log_download && (
                    <Paragraph size={300} marginRight={12}>NONE</Paragraph>
                  )}
                  {h.epub_download && (
                    <Paragraph 
                      size={300} 
                      marginRight={12} 
                      color="blue500"
                      onClick={e => {
                        setDownloads([h.epub_download]);
                        setShowdownloads(true);
                      }}
                    >
                      EPUB
                    </Paragraph>
                  )}
                  {h.pdf_download && (
                    <Paragraph 
                      size={300} 
                      marginRight={12} 
                      color="blue500"
                      onClick={e => {
                        setDownloads([h.pdf_download]);
                        setShowdownloads(true);
                      }}
                    >
                      PDF
                    </Paragraph>
                  )}
                  {h.cover_download && (
                    <Paragraph 
                      size={300} 
                      marginRight={12} 
                      color="blue500"
                      onClick={e => {
                        setDownloads([h.cover_download]);
                        setShowdownloads(true);
                      }}
                    >
                      Cover
                    </Paragraph>
                  )}
                  {h.log_download && (
                    <Paragraph 
                      size={300} 
                      marginRight={12} 
                      color="blue500"
                      onClick={e => {
                        setDownloads([h.log_download]);
                        setShowdownloads(true);
                      }}
                    >
                      Log file
                    </Paragraph>
                  )}
                </Pane>
                <Download 
                  show={showdownloads} 
                  handleSetShown={setShowdownloads} 
                  handleSetDownloading={setDownloading} 
                  handleAddHistory={props.handleAddHistory}
                  files={downloads} 
                  mode={mode} 
                  book_id={props.book_id}
                  user_id={props.user_id}
                  requestWithAuth={requestWithAuth}
                />
              </Pane>
            );
          }
        })}
      </Pane>
      <Pane marginTop={12} display="flex" flexDirection="row" justifyContent="flex-start">
        <Note handleChange={setNote} value={note} />
        <Button 
          marginLeft={12} 
          onClick={e => handleAddNote()}
        >
          Add Note
        </Button>
      </Pane>
    </Pane>
  );
}

export default BookTabs;