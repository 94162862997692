import React, {useContext, useState, useEffect} from 'react';
import axios from "axios";
import { Pane, Paragraph, CogIcon } from 'evergreen-ui';

import AuthContext from '../context/AuthContext';
import MainHeader from "../components/MainHeader";
import MainTabs from "../components/MainTabs";
import MainContent from "../components/MainContent";
import Status from "../components/Status";
import env from "react-dotenv";

const HomePage = () => {
    const { user, email, authTokens, logoutUser } = useContext(AuthContext);
    const [credentials, setCredentials] = useState(true);
    const [selectedTab, setSelectedTab] = useState("uploadtab");
    const [status, setStatus] = useState([]);
    const [updated, setUpdated] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [query, setQuery] = useState("");
    const [load, setLoad] = useState(true);

    const requestWithAuth = async (requestType, url, data={}) => {
        try {
            if (credentials) {
                let localAuthTokens = JSON.parse(localStorage.getItem('authTokens'));
                // let authToken = localAuthTokens.hasOwnProperty("access") ? localAuthTokens.access : localAuthTokens.refresh;
                let authToken = localAuthTokens.access;
                // const config = {headers: {Authorization: `Bearer ${credentials.hasOwnProperty("access") ? credentials.access : credentials.refresh}`}};
                const config = {headers: {Authorization: `Bearer ${authToken}`}};
                if (requestType === "put") {
                    const res = await axios.put(url, data, config);
                    return await res;
                } else if (requestType === "get") {
                    const res = await axios.get(url, config);
                    return await res;
                } else {
                    const res = await axios.post(url, data, config);
                    return await res;
                }
            }
        } catch (error) {
            logoutUser();
        }
    }

    const onSelectTab = (tab) => {
        setSelectedTab(tab);
    }

    const handleSetStatus = (item) => {
        setStatus(status => [...status, item]);
    }

    const fetch_conversion = async (conversion_id) => {
        const res = await requestWithAuth("get", `${env.BASE_URL}/api/conversions/${conversion_id}/`);
        return await res;
    }

    const set_conversion_status = async (conversion, status) => {
        let conversionId = conversion.data.id;
        let newData = conversion.data;
        newData.status = status;
        delete newData.id;
        const res = await requestWithAuth("put", `${env.BASE_URL}/api/conversions/${conversionId}/`, newData);
    }

    const update_status_item = (arr, item, ix, updates) => {
        let revised_arr = [...arr];
        let updated_item = item;
        for (let k in updates) {
            updated_item[k] = updates[k];
        }
        revised_arr[ix] = updated_item;
        return [revised_arr, updated_item];
    }

    const handleSearch = (query, tab="searchtab") => {
        setQuery(query);
        if (tab === "searchtab") {
            setSelectedTab("searchtab");
        }
    }

    const handleResetSearch = () => {
        setQuery("");
    }

    const handleLoadBooks = (tmpload) => {
        setLoad(tmpload);
    }

    // poll for updates, every 5 minutes a max of 4 times (20 minutes total);
    // set conversion status to FAIL if no update found after 20 minutes (max lambda timeout is 15min).
    useEffect(() => {
      if (status.length > 0) {
        const checkForStatusUpdates = async () => {
          for (let i=0; i<status.length; i++) {
            let item = status[i];
            // check the DB for any changes
            let conversion = await fetch_conversion(item.conversion_id);
            // if status has changed, update it in state
            if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 > 4) {
                console.log("nothing to be done");
            } else if (conversion.data.status !== item.status) {
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"status": conversion.data.status, "poll_count": 5});
                setStatus(status => revised_arr);
            } else if (item.hasOwnProperty("poll_count") && item["poll_count"]+1 === 4) {
                let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"status": "FAIL", "poll_count": poll_count});
                setStatus(status => revised_arr);
                setUpdated(updated => [...updated, updated_item]);
                set_conversion_status(conversion, "FAIL");
            } else {
                let poll_count = item.hasOwnProperty("poll_count") ? item["poll_count"]+1 : 1;
                let [revised_arr, updated_item] = update_status_item(status, item, i, {"poll_count": poll_count});
                setStatus(status => revised_arr);
            }
          }
        }

        // const intervalID = setInterval(checkForStatusUpdates, 30000, status);
        const intervalID = setInterval(checkForStatusUpdates, 240000, status);
      }
    }, [status]);

    useEffect(() => {
        setCredentials(authTokens);
    }, [authTokens]);

    useEffect(() => {
        if (!currentUser) {
        const getCurrentUser = async (user_id) => {
            const res = await requestWithAuth("post", `${env.BASE_URL}/api/user_roles/none/get_current_user_role/`, {user_id: user.user_id});
            if (res && res.data) {
                setCurrentUser({"role": res.data.role});
            }
        }
        getCurrentUser(user.user_id);
      }
    }, []);

    return (
        user ? (
        <Pane>
          <MainHeader requestWithAuth={requestWithAuth} handleSearch={handleSearch} handleLoadBooks={handleLoadBooks} />
          <Pane display="flex" flexDirection="row">
            <Pane 
              display="flex" 
              flexDirection="column" 
              alignItems="flex-start" 
              justifyContent="space-between" 
              width="300px"
              minHeight="100vh"
              border="default"
            >
                <Pane display="flex" alignItems="flex-start" justifyContent="flex-start" width="300px" flexDirection="column">
                    <MainTabs selectedTab={selectedTab} onSelectTab={onSelectTab} requestWithAuth={requestWithAuth} />
                    <Status items={status} requestWithAuth={requestWithAuth} />
                </Pane>
                <Pane display="flex" flexDirection="row" alignItems="center" padding={12} width="300px">
                    <CogIcon />
                    {currentUser && (
                        <Paragraph marginLeft={8} color="blue500" onClick={e => setSelectedTab("profiletab")}>
                            Signed in as: {email} {currentUser.role === "admin" && "(Admin)"}
                        </Paragraph>
                    )}
                </Pane>
            </Pane>
            <MainContent 
                query={query} 
                selectedTab={selectedTab} 
                load={load}
                handleLoadBooks={handleLoadBooks}
                handleSetStatus={handleSetStatus} 
                handleSearch={handleSearch}
                handleResetSearch={handleResetSearch} 
                user={user} 
                currentUser={currentUser} 
                requestWithAuth={requestWithAuth} 
            />
          </Pane>
        </Pane>
        ) : (
        <Pane>
          <Paragraph>You are not logged in - redirecting...</Paragraph>
        </Pane>
        )
    )
}

export default HomePage;