import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Pane, Heading, Paragraph, Link, Table, Select, Dialog, TextInputField, Button, IconButton, EditIcon } from 'evergreen-ui';
import AuthContext from '../context/AuthContext';
import FileAdmin from "./FileAdmin";
import env from "react-dotenv";

const Profile = (props) => {
	const { requestWithAuth } = props;
	let { logoutUser } = useContext(AuthContext);
	const [user, setUser] = useState(false);
	const [load, setLoad] = useState(true);
	const [show, setShow] = useState(false);

	const getUsers = async (num, filters) => {
    const res = await requestWithAuth("get", `${env.BASE_URL}/api/users/`);
    return await res;
  }

  const updateSelf = async (data) => {
		const res = await requestWithAuth("post", `${env.BASE_URL}/api/users/${props.user.user_id}/update_user_metadata/`, data);
    if (res && res.data) {
      setLoad(true);
    }
	}

  useEffect(() => {
    if (load === true) {
      async function fetchUser() {
        const res = await requestWithAuth("get", `${env.BASE_URL}/api/users/${props.user.user_id}/`);
        if (res && res.status === 200) {
          setUser(res.data);
        }
      }
      fetchUser();
      setLoad(false);
    }
  }, [user, load]);

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start" 
      justifyContent="flex-start" 
      padding={12}
    >
      <Pane marginBottom={32}>
      	<Pane display="flex" flexDirection="row" alignItems="center" marginBottom={16}>
		      <Heading>Your profile</Heading>
		      <IconButton icon={EditIcon} marginLeft={8} onClick={e => setShow(!show)} />
		    </Pane>
	      {user && (
	      	<Pane>
	      	  <Paragraph>Username: {user.username}</Paragraph>
	      	  <Paragraph>First Name: {user.first_name}</Paragraph>
	      	  <Paragraph>Last Name: {user.last_name}</Paragraph>
	      	  <Paragraph>Email: {user.email}</Paragraph>
	      	  <EditProfile show={show} setShow={setShow} data={user} updateSelf={updateSelf} />
	      	</Pane>
	      )}
	      <Pane marginTop={16}><Button onClick={e => logoutUser()}>Logout</Button></Pane>
	    </Pane>
      {props.role === "admin" && (
      	<Pane>
	      	<TeamAdmin requestWithAuth={requestWithAuth} />
	      	<FileAdmin requestWithAuth={requestWithAuth} />
	      </Pane>
      )}
    </Pane>
  );
}

const TeamAdmin = (props) => {
	const { requestWithAuth } = props;
	const [load, setLoad] = useState(true);
	const [users, setUsers] = useState([]);

	const reload = () => {
		setLoad(true);
	}

  useEffect(() => {
    if (load === true) {
      async function fetchUsers() {
        const res = await requestWithAuth("get", `${env.BASE_URL}/api/users/`);
        if (res && res.status === 200) {
          let usersTmp = res.data.results;
          const roles_res = await requestWithAuth("get", `${env.BASE_URL}/api/user_roles/`);
          if (roles_res && roles_res.status === 200) {
          	for (let i=0; i<usersTmp.length; i++) {
          		let user = usersTmp[i];
          		for (let j=0; j<roles_res.data.results.length; j++) {
          			let role = roles_res.data.results[j];
          			if (role.user === user.username) {
          				usersTmp[i]["role"] = role.role.name;
          			}
          		}
          	}
          	setUsers(usersTmp);
          }
        }
      }
      fetchUsers();
      setLoad(false);
    }
  }, [users, load]);


  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start" 
      justifyContent="flex-start" 
      width="900px"
    >
      <Heading marginBottom={16}>Team Admin</Heading>
      <Table width="880px" marginBottom={16}>
			  <Table.Head>
			    <Table.TextHeaderCell>Email</Table.TextHeaderCell>
			    <Table.TextHeaderCell>Last Activity</Table.TextHeaderCell>
			    <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>Role</Table.TextHeaderCell>
			    <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
			  </Table.Head>
			  <Table.Body>
			    {users.map(user => <User data={user} key={user.username} requestWithAuth={requestWithAuth} reload={reload} />)}
			  </Table.Body>
			</Table>
    	<Link href={`${env.BASE_URL}/admin/`} marginRight={12} target="_blank">
		    View database admin...
		  </Link>
    </Pane>
  );
}

const User = (props) => {
	const { requestWithAuth } = props;
	const [role, setRole] = useState("user");
	const [changes, setChanges] = useState(false);

	const updateRole = async () => {
		const res = await requestWithAuth("post", `${env.BASE_URL}/api/user_roles/none/update_user_role/`, {user_id: props.data.id, role: role});
    if (res && res.data) {
      setChanges(false);
      props.reload();
    }
	}

	const deleteUser = async () => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/users/${props.data.id}/delete_user/`, {id: props.data.id});
    if (res && res.data) {
      props.reload();
    }
  }

	useEffect(() => {
    setRole(props.data.role);
  }, [props.data.role]);

  return (
  	<Table.Row>
      <Table.TextCell>{props.data.email}</Table.TextCell>
      <Table.TextCell>{props.data.last_login}</Table.TextCell>
      <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
      	<Select 
      		value={role} 
      		onChange={e => {
      			setRole(e.target.value);
      			setChanges(!(e.target.value === props.data.role));
      		}}
      	>
		      <option value="user">User</option>
		      <option value="admin">Admin</option>
		    </Select>
      </Table.TextCell>
      <Table.TextCell>
        <Button 
        	disabled={!changes} 
        	onClick={e => {
        		updateRole();
        	}}
        >
        	Save Changes
        </Button>
      	<Button appearance="primary" intent="danger" marginLeft={8} onClick={e => deleteUser()}>Delete User</Button>
      </Table.TextCell>
    </Table.Row>
  );
}

const EditProfile = (props) => {
	const [username, setUsername] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');	

	const handleUpdateSelf = () => {
		const data = {
			username: username,
			first_name: firstname,
			last_name: lastname,
			email: email
		};
		props.updateSelf(data);
	}

	useEffect(() => {
    setUsername(props.data.username);
  }, [props.data.username]);

  useEffect(() => {
    setFirstname(props.data.first_name);
  }, [props.data.first_name]);

  useEffect(() => {
    setLastname(props.data.last_name);
  }, [props.data.last_name]);

  useEffect(() => {
    setEmail(props.data.email);
  }, [props.data.email]);

	return (
	  <Pane>
    	<Dialog
        isShown={props.show}
        title="Edit Profile"
        hasCancel={true}
        onCloseComplete={() => props.setShow(false)}
        confirmLabel="Save Changes"
        onConfirm={(close) => {
        	handleUpdateSelf();
        	close();
        }}
      >
        <TextInputField
				  label="Username"
				  placeholder={props.data.username}
				  value={username}
      		onChange={e => setUsername(e.target.value)}
				/>
				<TextInputField
				  label="First Name"
				  placeholder={props.data.first_name}
				  value={firstname}
      		onChange={e => setFirstname(e.target.value)}
				/>
				<TextInputField
				  label="Last Name"
				  placeholder={props.data.last_name}
				  value={lastname}
      		onChange={e => setLastname(e.target.value)}
				/>
				<TextInputField
				  label="Email"
				  placeholder={props.data.email}
				  value={email}
      		onChange={e => setEmail(e.target.value)}
				/>
	    </Dialog>
    </Pane>
	);
}

export default Profile;