import React, { useEffect, useState } from "react";
import axios from "axios";
import { Pane, Heading, Paragraph, Button, ArrowRightIcon, ArrowLeftIcon } from 'evergreen-ui';
import env from "react-dotenv";
import Download from "./Download";

const FileAdmin = (props) => {
	const { requestWithAuth } = props;
	const [files, setFiles] = useState([]);
	const [page, setPage] = useState(1); // 1-indexed
	const [afters, setAfters] = useState([]);
	const [load, setLoad] = useState(true);
	const limit = 25; // 100

	const handleDeleteFile = (key) => {
		let tmpFiles = files.filter(f => f.Key !== key);
		setFiles(tmpFiles);
		let tmpAfters = afters.map(f => {
			if (f === key) {
				return tmpFiles[tmpFiles.length-1].Key;
			} else {
				return f;
			}
		});
		setAfters(tmpAfters);
	}

	const loadPrev = async () => {
		let key = page-2 > 0 ? afters[page-3] : null;
		const res = await requestWithAuth("post", `${env.BASE_URL}/api/file_admins/none/list_all_files_in_s3/`, {key: key});
		if (res && res.status === 200) {
      let lastFile = res.data.files.Contents[res.data.files.Contents.length-1];
			// set the files list to this page
      setFiles(res.data.files.Contents);
      // set the new page
			setPage(page > 1 ? page-1 : 0);
    }
	}

	const loadNext = async () => {
		const res = await requestWithAuth("post", `${env.BASE_URL}/api/file_admins/none/list_all_files_in_s3/`, {key: files[files.length-1].Key});
		if (res && res.status === 200) {
			let lastFile = res.data.files.Contents[res.data.files.Contents.length-1];
			// if this is an as-yet unseen page, add to list of afterpoints
			if (afters.length < page+1) {
				setAfters(afters => [...afters, lastFile.Key]);
			}
			// set the files list to this page
      setFiles(res.data.files.Contents);
      // set the new page
      setPage(page+1);
    }
	}

  useEffect(() => {
    if (load === true) {
      async function fetchFiles() {
        const res = await requestWithAuth("post", `${env.BASE_URL}/api/file_admins/none/list_all_files_in_s3/`, {});
        if (res && res.status === 200) {
          setFiles(res.data.files.Contents);
          setAfters([res.data.files.Contents[res.data.files.Contents.length-1].Key]);
        }
      }
      fetchFiles();
      setLoad(false);
    }
  }, [files, load]);

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start" 
      justifyContent="flex-start" 
      marginTop={32}
    >
    	<Heading marginBottom={16}>S3 File Admin</Heading>
    	{files.map(file => <File key={file.Key} data={file} requestWithAuth={requestWithAuth} handleDeleteFile={handleDeleteFile} />)}
    	{files.length > 0 && (
	    	<Pane display="flex" flexDirection="row" alignItems="center">
		    	<Button iconBefore={ArrowLeftIcon} marginRight={12} onClick={e => loadPrev()} disabled={page===1}>Previous {limit}</Button>
		    	<Button iconAfter={ArrowRightIcon} onClick={e => loadNext()}>Next {limit}</Button>
		    </Pane>
		  )}
    </Pane>
  );
}

const File = (props) => {
	const { requestWithAuth } = props;
  const [showdownloads, setShowdownloads] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const deleteFile = async (key) => {
  	const res = await requestWithAuth("post", `${env.BASE_URL}/api/file_admins/none/delete_file_from_s3/`, {"key": key});
    console.log(res);
    if (res && res.status === 200) {
      props.handleDeleteFile(key);
      // remove the file from the list as well
    }
  }

	return (
		<Pane display="flex" flexDirection="row" alignItems="center" marginBottom={12}>
			<Button 
			  marginRight={12}
        disabled={downloading} 
        onClick={e => {
          setShowdownloads(true);
        }}
      >
        {downloading ? "Downloading..." : "Download"}
      </Button>
			<Button 
				appearance="primary" 
				intent="danger" 
				marginRight={12}
				onClick={e => deleteFile(props.data.Key)}
			>
				Delete
			</Button>
			<Paragraph>{props.data.Key}</Paragraph>
			<Download 
        show={showdownloads} 
        handleSetShown={setShowdownloads} 
        handleSetDownloading={setDownloading} 
        handleAddHistory={props.handleAddHistory}
        files={[props.data.Key]} mode={"local"} 
        book_id={props.book_id}
        user_id={props.user_id}
        requestWithAuth={requestWithAuth}
      />
		</Pane>
	);
}

export default FileAdmin;