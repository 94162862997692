import React from "react";
import { Pane, Paragraph } from 'evergreen-ui';

const ReplacementsList = (props) => {
  return (
    <Pane>
      {props.replacements.map(r => <RenderedReplacement key={r.page} r={r} />)}
    </Pane>
  );
}

const RenderedReplacement = (props) => {
  return (
    <Pane marginLeft={16}>
      {props.r.mode === "insert" ? (
        <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          <Paragraph size={300} marginRight={8}>{props.r.mode}</Paragraph>
          <Paragraph size={300} marginRight={8}>{props.r.file[0].path}</Paragraph>
          <Paragraph size={300} marginRight={8}>{props.r.position}</Paragraph>
          <Paragraph size={300}>page {props.r.page}</Paragraph>
        </Pane>
      ) : (
        <Pane display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          <Paragraph size={300} marginRight={8}>{props.r.mode}</Paragraph>
          <Paragraph size={300} marginRight={8}>{props.r.page}</Paragraph>
          <Paragraph size={300} marginRight={8}>with</Paragraph>
          <Paragraph size={300}>{props.r.file[0].path}</Paragraph>
        </Pane>
      )}
    </Pane>
  )
}

export default ReplacementsList;