import React, { Component } from "react";
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import SearchBar from "./SearchBar";
import logo from '../kup-logo-lowres.png';

const MainHeader = (props) => {
  return (
    <Pane 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      justifyContent="space-between" 
      height={64} 
      width="100%"
      padding={12}
      borderTop="2px solid black"
      borderBottom="2px solid black"
    >
      <Pane 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="flex-start" 
      >
        <img src={logo} alt="Kodansha Logo" height="40" />
        <Heading size={600} lineHeight="40px">Conversion Tool</Heading>
      </Pane>
      <SearchBar handleSearch={props.handleSearch} handleLoadBooks={props.handleLoadBooks} />
    </Pane>
  );
}

export default MainHeader;