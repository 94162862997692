import React, { useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Pane, Paragraph, TextInput, Checkbox, Button } from 'evergreen-ui';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const SearchOptions = (props) => {
  const [query, setQuery] = useState("");
  const [firstConversion, setFirstConversion] = useState(false);
  const [lastConversion, setLastConversion] = useState(false);
  const [firstDate, setFirstDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  const assembleQuery = () => {
    return `${query}${firstConversion ? '&f='+firstDate : ""}${lastConversion ? '&l='+lastDate : ""}`;
  }

  return (
    <Pane display="flex" flexDirection="column" marginY={16} marginX={16}>
      <TextInput onChange={e => setQuery(e.target.value)} value={query} placeholder="Title, author, or ISBN..." marginBottom={8} />
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Date of first conversion:"
          checked={firstConversion}
          onChange={e => setFirstConversion(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
        <DatePicker 
          disabled={!firstConversion} 
          selected={firstDate} 
          onChange={(date) => {
            setFirstDate(date.getTime());
          }} 
        />
      </Pane>
      <Pane display="flex" flexDirection="row" alignItems="center">
        <Checkbox 
          label="Date of last conversion:"
          checked={lastConversion}
          onChange={e => setLastConversion(e.target.checked)} 
          marginRight={8}
          marginY={8}
        />
        <DatePicker 
          disabled={!lastConversion} 
          selected={lastDate} 
          onChange={(date) => {
            setLastDate(date.getTime());
          }} 
        />
      </Pane>
      <Paragraph marginTop={8}>
        <Button 
          onClick={e => {
            let tmpQuery = assembleQuery();
            props.handleSearch(tmpQuery);
            setQuery("");
            props.handleLoadBooks(true);
          }} 
        >
          Search
        </Button>
      </Paragraph>
    </Pane>
  );
}

export default SearchOptions;
