import React, { useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import { Pane, TextInput, IconButton, SearchIcon } from 'evergreen-ui';

const SearchBar = (props) => {
	const [query, setQuery] = useState("");

	const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('Enter key pressed');
      props.handleSearch(query);
    }
  };
  
  return (
    <Pane display="flex" flexDirection="row">
      <TextInput onChange={e => setQuery(e.target.value)} value={query} placeholder="Search for a title, author, or ISBN..." />
      <IconButton 
      	icon={SearchIcon} 
      	onClick={e => {
      		props.handleSearch(query);
      		setQuery("");
      		props.handleLoadBooks(true);
      	}} 
      	onKeyDown={handleKeyDown} 
      />
    </Pane>
  );
}

export default SearchBar;
