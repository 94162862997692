import React, { useState } from "react";
import axios from "axios";
import https from "https-browserify";
import { Pane, Heading, Paragraph } from 'evergreen-ui';
import getFormattedTimestr from "../utils/helpers";
import BatchUpload from "./BatchUpload";
import ManualUpload from "./ManualUpload";
import Books from "./Books";
import Profile from "./Profile";
import SearchOptions from "./SearchOptions";
import ReportOptions from "./ReportOptions";
import env from "react-dotenv";

const MainContent = (props) => {
  const { requestWithAuth } = props;
  const [searchCount, setSearchCount] = useState(0);

  const handleSetSearchCount = (count) => {
    setSearchCount(count);
  }

  const triggerConvert = async (item) => {
    const res = await requestWithAuth("post", `${env.BASE_URL}/api/books/none/trigger_convert/`, {item: item, user_id: props.user.user_id});
    return await res;
  }

  const put = (url, data) => {
    const options = {
      hostname: env.HOSTNAME,
      path: url.replace(/https:\/\/[^\/]+/, ""),
      method: 'PUT',
    };
    return new Promise((resolve, reject) => {
      const req = https.request(
        options,
        (res) => {
          let responseBody = "";
          res.on("data", (chunk) => {
            responseBody += chunk;
          });
          res.on("end", () => {
            if (res.statusCode >= 200 && res.statusCode <= 299) {
              resolve(true);
            } else {
              reject(false);
            }
          });
        },
      );
      req.on("error", (err) => {
        reject(err);
      });
      req.write(data);
      req.end();
    });
  };

  const readFileAndUpload = async (file, key, item) => {
    if (file) {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        const binaryStr = reader.result;
        const url_res = await requestWithAuth("props", `${env.BASE_URL}/api/books/none/get_presigned_url/`, {"key": key, "method": "put_object"});
        let upload = await put(url_res.data.url, Buffer.from(binaryStr));
        return upload;
      }
      reader.readAsArrayBuffer(file);
    }
  };

  // ignore this -- resetting highlighting

  const triggerConversion = async (item, note, conversionSettings) => {
    // create the timestr
    const timestr = getFormattedTimestr(); // "%Y%m%d-%H%M%S"
    item["timestr"] = timestr;
    if (note && note.match(/\S/)) {
      item["notes"] = note;
    }
    item["options"] = conversionSettings;
    // upload relevant files to s3
    if (item.hasOwnProperty("cover")) {
      // upload cover to s3
      let cover_ext = item["cover"].match(/\.[^\/]+$/);
      let filename = item["eisbn"]+"_COVER"+cover_ext;
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["cover_file"];
      let uploaded = await readFileAndUpload(file, key, item);
      item["cover_key"] = key;
    }
    if (item.hasOwnProperty("copyright")) {
      // upload copyright to s3
      let filename = item["eisbn"]+"_COPYRIGHT.pdf";
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["copyright_file"];
      let uploaded = await readFileAndUpload(file, key, item);
      item["copyright_key"] = key;
    }
    // Upload replacement pages
    if (item.hasOwnProperty("replacements")) {
      for (let i=0; i<item.replacements.length; i++) {
        let r = item.replacements[i];
        let filename = "replacement-"+r.page+".pdf";
        let key = "INPUT-" + timestr + "/" + filename;
        r["key"] = key;
        let uploaded = await readFileAndUpload(r.file[0], key, item);
      }
    }
    if (item.hasOwnProperty("interior")) {
      // upload interior to s3
      let filename = item["eisbn"]+".pdf";
      let key = "INPUT-" + timestr + "/" + filename;
      let file = item["interior_file"];
      let uploaded = await readFileAndUpload(file, key, item);
      item["interior_key"] = key;
    }
    // trigger the conversion after interior is uploaded;
    // FYI the lambda will throw an error here if files aren't in s3
    let res = await triggerConvert(item);
    if (res && res.data) {
      item["conversion_id"] = res.data.id;
      item["status"] = "IN_PROGRESS";
      props.handleSetStatus(item);
    }
    return;
  }

  const tabContentMap = {
    "uploadtab": <UploadContent handleSetStatus={props.handleSetStatus} handleTriggerConversion={triggerConversion} requestWithAuth={requestWithAuth} />,
    "bookstab": <BooksContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={triggerConversion} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "reportstab": <ReportContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} searchCount={searchCount} handleSearch={props.handleSearch} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={triggerConversion} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "searchtab": <SearchContent load={props.load} handleLoadBooks={props.handleLoadBooks} query={props.query} searchCount={searchCount} handleSearch={props.handleSearch} handleResetSearch={props.handleResetSearch} handleSetSearchCount={handleSetSearchCount} handleTriggerConversion={triggerConversion} user={props.user} currentUser={props.currentUser} requestWithAuth={requestWithAuth} />,
    "profiletab": <ProfileContent user={props.user} role={props.currentUser ? props.currentUser.role : "user"} requestWithAuth={requestWithAuth} />
  }

  return (
    <Pane 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="flex-start" 
      padding={12}
    >
      {tabContentMap[props.selectedTab]}
    </Pane>
  );
}

// ignore this -- resetting highlighting

const UploadContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Pane>
      <BatchUpload handleTriggerConversion={props.handleTriggerConversion} requestWithAuth={requestWithAuth} />
      <ManualUpload handleTriggerConversion={props.handleTriggerConversion} requestWithAuth={requestWithAuth} />
    </Pane>
  );
}

const BooksContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Books 
      load={props.load}
      handleLoadBooks={props.handleLoadBooks}
      query={""} 
      handleResetSearch={props.handleResetSearch} 
      handleSetSearchCount={props.handleSetSearchCount} 
      handleTriggerConversion={props.handleTriggerConversion} 
      user={props.user} 
      currentUser={props.currentUser}
      requestWithAuth={requestWithAuth} 
    />
  );
}

const ReportContent = (props) => {
  const { requestWithAuth } = props;
  const [advanced, setAdvanced] = useState(false);

  return (
    <Pane>
      <Heading marginLeft={16}>Create a Report</Heading>
      <ReportOptions handleLoadBooks={props.handleLoadBooks} handleSearch={props.handleSearch} />
      <Books 
        load={props.load}
        mode="report"
        handleLoadBooks={props.handleLoadBooks}
        query={props.query} 
        handleResetSearch={props.handleResetSearch} 
        handleSetSearchCount={props.handleSetSearchCount} 
        handleTriggerConversion={props.handleTriggerConversion} 
        user={props.user} 
        currentUser={props.currentUser}
        requestWithAuth={requestWithAuth} 
      />
    </Pane>
  );
}

const SearchContent = (props) => {
  const { requestWithAuth } = props;
  const [advanced, setAdvanced] = useState(false);

  return (
    <Pane>
      <Heading marginLeft={16}>Search Results ({props.searchCount})</Heading>
      <Paragraph 
        size={300} 
        color="blue500"
        marginTop={8}
        marginLeft={16}
        onClick={e => setAdvanced(!advanced)}
      >
        {advanced ? "- Hide" : "+ Show"} advanced search options
      </Paragraph>
      {advanced && (
        <SearchOptions handleLoadBooks={props.handleLoadBooks} handleSearch={props.handleSearch} />
      )}
      <Books 
        load={props.load}
        mode="report"
        handleLoadBooks={props.handleLoadBooks}
        query={props.query} 
        handleResetSearch={props.handleResetSearch} 
        handleSetSearchCount={props.handleSetSearchCount} 
        handleTriggerConversion={props.handleTriggerConversion} 
        user={props.user} 
        currentUser={props.currentUser}
        requestWithAuth={requestWithAuth} 
      />
    </Pane>
  );
}

const ProfileContent = (props) => {
  const { requestWithAuth } = props;

  return (
    <Profile user={props.user} role={props.role} requestWithAuth={requestWithAuth} />
  );
}

export default MainContent;